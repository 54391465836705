import React, { Component, useState } from "react";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Modal, message, Spin, Button } from "antd";
import { HiMenu } from "react-icons/hi";

import {
  UserOutlined,
  ReloadOutlined,
  BankOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import Bridge from "../constants/Bridge";
import Apis from "../constants/Apis";
import moment from "moment";
import Membership from "./modal/Membership";
import { TRACKING_ID } from "../constants/data";
import ReactGA from "react-ga4";
ReactGA.initialize(TRACKING_ID);
class NewWebHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isActive: false,
      isScrolled: 0,
      loggedinstatus: false,
      modalVisible: false,
      bankdetailsmodal: false,
      profiledetailsmodal: false,
      founder_profiledetailsmodal: false,
      accountno: "",
      ifsccode: "",
      investor_id: "",
      formloader: false,
      loggedinuser: "",
      profile: "",
      firstname: "",
      middlename: "",
      lastname: "",
      contactno: "",
      membership_type: "",
      profileimagetoshow: "",
      communitylink: "/Login",
      discounted_amount: 0,
      membership_amount: 0,
      investor_kyc_status: "",

      //for founder data
      founder_profileimagetoshow: "",
      founder_profile: "",
      founder_contactno: "",
      founder_lastname: "",
      founder_middlename: "",
      founder_firstname: "",
      premium_button_show_status: false,
      // founder_membership_type:'',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    // this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    // window.addEventListener("scroll", this.handleScroll);
    var scroll_pos = 0;
    var scroll_time;

    $(window).scroll(function () {
      clearTimeout(scroll_time);
      var current_scroll = $(window).scrollTop();

      if (current_scroll >= $("#topNav").outerHeight()) {
        if (current_scroll <= scroll_pos) {
          $("#topNav").removeClass("hidden");
        } else {
          $("#topNav").addClass("hidden");
        }
      }

      scroll_time = setTimeout(function () {
        scroll_pos = $(window).scrollTop();
      }, 100);
    });
    let lastScroll = 0;
    $(document).ready(function ($) {
      $(window).scroll(function () {
        setTimeout(function () {
          //gives 100ms to finish scrolling before doing a check
          var scroll = $(window).scrollTop();
          if (scroll > lastScroll) {
            $(".selector").addClass("shift");
          } else if (scroll < lastScroll) {
            $(".selector").removeClass("shift");
          }
          lastScroll = scroll;
        }, 100);
      });
      $(document).ready(function () {
        var s = $(".main-header");
        var pos = s.position();
        $(window).scroll(function () {
          var windowpos = $(window).scrollTop();
          if ((windowpos >= pos.top) & (windowpos <= 100)) {
            s.removeClass("bgheaders");
          } else {
            s.addClass("bgheaders");
          }
        });
      });
    });

    $(".main-header").hover(
      function () {
        $(this).addClass("bgheaders");
      },
      function () {
        $(this).removeClass("bgheaders");
      }
    );

    // const expand
    // this.isLogin();

    if (localStorage.getItem("investor_id")) {
      this.setState(
        {
          name: localStorage.getItem("investor_name"),
          loggedinstatus: true,
          loggedinuser: "investor",
          investor_id: localStorage.getItem("investor_id"),
        },
        () => this.getbankdetails()
      );
    } else if (localStorage.getItem("founder_id")) {
      this.setState(
        {
          loggedinstatus: true,
          loggedinuser: "founder",
          name: localStorage.getItem("founder_name"),
        },
        () => this.get_founder_details()
      );
    } else {
      this.setState({
        name: "",
        loggedinstatus: false,
      });
    }
    this.getsettings();
  }
  componentWillUnmount() {
    // window.removeEventListener("scroll", this.handleScroll);
  }

  toggleMenu() {
    // Toggle isActive state

    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  // });

  // isLogin = () => {
  //   if(localStorage.getItem("investor_id")  ||
  //   localStorage.getItem("founder_id")){
  //     window.location.replace('/deals');
  //   }
  // }

  getsettings = () => {
    this.setState({ amountloader: true });
    Bridge.admin.settings
      .getsettings()
      .then((result) => {
        if (result.status == 1) {
          // console.log('result',result);
          let amount =
            Number(result.data[0].amount / 100) *
            Number(result.data[0].discount);
          amount = Number(result.data[0].amount) - Number(amount);
          this.setState({
            membership_amount: result.data[0].amount,
            discount: result.data[0].discount,
            discounted_amount: amount,
          });
        } else {
        }
      })
      .catch((err) => { });
  };

  getbankdetails = () => {
    let params = {
      id: this.state.investor_id,
    };
    Bridge.investor.getbankdetails(params).then((result) => {
      if (result.status == 1) {
        //checking user block status
        if (result.data[0].user_block_status == 0) {
          let url =
            Apis.IMAGEURL +
            "profile/" +
            result.data[0].investor_id +
            "/" +
            result.data[0].user_profile_picture;
          // console.log('result',result);
          this.setState({
            accountno: result.data[0].bank_ac_no,
            ifsccode: result.data[0].ifsc_code,
            firstname: result.data[0].first_name,
            middlename: result.data[0].middle_name,
            lastname: result.data[0].last_name,
            contactno: result.data[0].mobile,
            profileimagetoshow: result.data[0].user_profile_picture ? url : "",
            membership_type: result.data[0].membership_type,
            investor_kyc_status: result.data[0].kycstatus,
          });
          let exploded_start_date = "";
          let exploded_end_date = "";
          let start_date = result.data[0].membership_start_date;
          if (start_date) {
            let explodeddate = start_date.split(" ")[0];
            exploded_start_date = explodeddate;
            start_date = moment(explodeddate).format("DD MMM, YYYY");
            this.setState({ membership_start_date: start_date });
          }
          let end_date = result.data[0].membership_end_date;
          if (end_date) {
            let explodeddate = end_date.split(" ")[0];
            exploded_end_date = explodeddate;
            let current_date = moment().format("YYYY-MM-DD");
            let test_end_date = moment(exploded_end_date).format("YYYY-MM-DD");
            let sub_1_end_date = moment(exploded_end_date)
              .subtract(1, "months")
              .format("YYYY-MM-DD");
            // console.log('sub_1_end_date',sub_1_end_date);
            if (current_date > sub_1_end_date && current_date < test_end_date) {
              this.setState({ premium_button_show_status: true });
            } else {
              this.setState({ premium_button_show_status: false });
            }
            end_date = moment(explodeddate).format("DD MMM, YYYY");
            this.setState({
              membership_end_date: end_date,
              expiry_date: exploded_end_date,
            });
          }
          if (exploded_start_date && exploded_end_date) {
            let current_date = moment();
            let ending_date = moment(exploded_end_date);
            let starting_date = moment(exploded_start_date).add(11, "M");
            let d = new Date(exploded_start_date);
            var newDate = new Date(d.setMonth(d.getMonth() + 11));
            let s_d = moment(newDate);
            if (current_date >= s_d && current_date <= ending_date) {
              // console.log('expired');
              this.setState({ membership_expired_status: true });
            }
          }
          // check for button show

          this.setState({ formloader: false });
        } else {
          message.warning(
            "Your Login Id/Email has been block, Please Contact to Administrator",
            5
          );
          localStorage.clear();
          window.location.assign("/Login");
        }
      } else {
        this.setState({ formloader: false });
      }
    });
  };

  //for upgrade membership
  // pay
  pay = () => {
    let order_id = "order-01";
    let user_id = localStorage.getItem("investor_id");
    let amount = this.state.discounted_amount;
    let membership_fees = this.state.discounted_amount;
    let registered_amt = this.state.membership_amount;
    let url = `${process.env.REACT_APP_BASE_URL}cashfree/register/checkout.php?user_id=${user_id}&order_id=${order_id}&amount=${amount}&membership_fees=${membership_fees}&registered_amt=${registered_amt}`;
    window.location.assign(url);
  };

  get_founder_details = () => {
    let params = {
      founder_id: localStorage.getItem("founder_id"),
    };
    Bridge.founder.get_founder_profile_details(params).then((result) => {
      if (result.status == "1") {
        let url =
          Apis.IMAGEURL +
          "profile/" +
          result.data[0].investor_id +
          "/" +
          result.data[0].user_profile_picture;
        // console.log('result',result);
        if (result.data[0].user_block_status == 0) {
          this.setState({
            founder_firstname: result.data[0].first_name,
            founder_middlename: result.data[0].middle_name,
            founder_lastname: result.data[0].last_name,
            founder_contactno: result.data[0].mobile,
            founder_profileimagetoshow: result.data[0].user_profile_picture
              ? url
              : "",
            // founder_membership_type: result.data[0].membership_type
          });
        } else {
          message.warning(
            "Your Login Id/Email has been block, Please Contact to Administrator",
            5
          );
          localStorage.clear();
          window.location.assign("/Login");
        }
      } else {
        this.setState({ formloader: false });
      }
    });
  };
  handleCancel = () => {
    this.setState({
      modalVisible: false,
    });
  };

  logout = () => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.event({
      category: "Logout",
      action: "Logged Out",
    });
    localStorage.clear();
    window.location.href = "/login";
  };

  showbankingdetails = () => {
    this.setState({
      bankdetailsmodal: true,
    });
  };

  handleCancelbankdetails = () => {
    this.setState({
      bankdetailsmodal: false,
    });
  };

  updatebankdetails = () => {
    if (
      this.state.accountno == "" ||
      this.state.accountno.length < 9 ||
      this.state.accountno.length > 18
    ) {
      message.warning("Please enter account number");
      return;
    }
    if (this.state.ifsccode == "") {
      message.warning("Please enter IFSC Code");
      return;
    }
    if (this.state.ifsccode.length != 11) {
      message.warning("Please enter valid IFSC Code");
      return;
    }

    if (this.state.investor_id == "") {
      message.warning("Invalid Request");
      return;
    }
    var reg = /^[A-Za-z]{4}[0-9]{6,7}$/;
    if (this.state.ifsccode.match(reg)) {
    } else {
      message.warning("Invalid ifsc code.");
      return;
    }

    this.setState({ formloader: true });

    let params = {
      accountno: this.state.accountno,
      ifsccode: this.state.ifsccode,
      id: this.state.investor_id,
    };

    Bridge.investor.updateaccountdetails(params).then((result) => {
      if (result.status == 1) {
        message.success(result.message);
        this.setState({
          formloader: false,
          bankdetailsmodal: false,
        });
      } else {
        message.warning(result.message);
        this.setState({
          formloader: false,
        });
      }
    });
  };

  // show profile details
  showprofiledetailspopup = () => {
    this.setState({
      profiledetailsmodal: true,
    });
  };

  // hide profile details
  hideprofiledetailspopup = () => {
    this.setState({
      profiledetailsmodal: false,
    });
    window.location.reload();
  };

  // updating profile details
  updateprofiledetails = () => {
    if (this.state.firstname == "") {
      message.warning("First name is required");
      return false;
    } else if (this.state.lastname == "") {
      message.warning("Last name is required");
      return false;
    } else if (
      this.state.contactno == "" ||
      this.state.contactno.length != 10
    ) {
      message.warning("Contact number is required");
      return false;
    }
    this.setState({ formloader: true });

    let formData = new FormData(); //formdata object

    formData.append("first_name", this.state.firstname); //append the values with key, value pair
    formData.append("middle_name", this.state.middlename);
    formData.append("last_name", this.state.lastname);
    formData.append("mobile", this.state.contactno);
    formData.append("membership_type", this.state.membership_type);
    formData.append("user_profile_picture", this.state.profile);
    formData.append("investor_id", this.state.investor_id);
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (localStorage.getItem("investor_id")) {
      Bridge.investor.updateprofiledetails(formData, config).then((result) => {
        if (result.status == 1) {
          message.success(result.message);
          this.setState(
            {
              formloader: false,
              profiledetailsmodal: false,
              firstname: "",
              lastname: "",
              contactno: "",
              profile: "",
            },
            () => this.getbankdetails()
          );
          window.location.reload();
        } else {
          message.error(result.message);
          this.setState({
            formloader: false,
          });
        }
      });
    }
  };

  //  showing Founder profile details
  founder_profiledetailspopup = () => {
    this.setState({
      founder_profiledetailsmodal: true,
    });
  };

  // hide founder profile details
  founder_hideprofiledetailspopup = () => {
    this.setState({
      founder_profiledetailsmodal: false,
    });
    window.location.reload();
  };

  // updating founder profile details

  founder_updateprofiledetails = () => {
    if (this.state.founder_firstname == "") {
      message.warning("First name is required");
      return false;
    } else if (this.state.founder_lastname == "") {
      message.warning("Last name is required");
      return false;
    } else if (
      this.state.founder_contactno == "" ||
      this.state.founder_contactno.length != 10
    ) {
      message.warning("Contact number is required");
      return false;
    }
    this.setState({ formloader: true });

    let formData = new FormData(); //formdata object

    formData.append("founder_first_name", this.state.founder_firstname); //append the values with key, value pair
    formData.append("founder_middle_name", this.state.founder_middlename);
    formData.append("founder_last_name", this.state.founder_lastname);
    formData.append("founder_mobile", this.state.founder_contactno);
    formData.append("founder_user_profile_picture", this.state.founder_profile);
    console.log(this.state.founder_profile);
    formData.append("founder_id", localStorage.getItem("founder_id"));

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    if (localStorage.getItem("founder_id")) {
      Bridge.founder
        .update_founder_profile_details(formData, config)
        .then((result) => {
          if (result.status == 1) {
            message.success(result.message);
            this.setState(
              {
                formloader: false,
                founder_profiledetailsmodal: false,
                firstname: "",
                lastname: "",
                contactno: "",
                profile: "",
              },
              () => this.getbankdetails()
            );
            window.location.reload();
          } else {
            message.error(result.message);
            this.setState({
              formloader: false,
            });
          }
        });
    }
  };

  checkforlogin = () => {
    if (localStorage.getItem("investor_id")) {
      let name = this.state.firstname + " " + this.state.lastname;
      let img = this.state.profileimagetoshow;
      let investor_id = localStorage.getItem("investor_id");
      let investor_email = localStorage.getItem("investor_email");
      // let loc = `http://growth91.myakola.com/api/Users/setsignindata?email=${investor_email}&user_id=${investor_id}`;
      let loc = `${process.env.REACT_APP_BASE_URL}community/test.php?email=${investor_email}&user_id=${investor_id}&name=${name}&img=${img}`;
      // window.location.assign(loc);
      window.location.assign("/UnderMaintenance");
    } else if (localStorage.getItem("founder_id")) {
      let name =
        this.state.founder_firstname + " " + this.state.founder_lastname;
      let img = this.state.founder_profileimagetoshow;
      let investor_id = localStorage.getItem("founder_id");
      let investor_email = localStorage.getItem("founder_email");
      // console.log('founder_name',investor_id)
      // let loc = `http://growth91.myakola.com/api/Users/setsignindata?email=${investor_email}&user_id=${investor_id}`;
      let loc = `${process.env.REACT_APP_BASE_URL}community/test.php?email=${investor_email}&user_id=${investor_id}&name=${name}&img=${img}`;
      // window.location.assign(loc);
      window.location.assign("/UnderMaintenance");
    } else {
      window.location.assign("/Login");
    }
  };
  open_menu = () => {
    $(".mobile-menu").addClass("active");
    $(".menu-icon .open-menu").addClass("d-none").removeClass("d-block");
    $(".menu-icon .close-menu").removeClass("d-none").addClass("d-block");
    // alert('menu opened');
  };
  close_menu = () => {
    $(".mobile-menu").removeClass("active");
    $(".menu-icon .open-menu").removeClass("d-none").addClass("d-block");
    $(".menu-icon .close-menu").addClass("d-none").removeClass("d-block");
    // alert('menu closed');
  };

  render() {
    const { newabout } = this.props;

    // Add a click event listener to the expandMenu element
    const { isActive, isScrolled } = this.state;
    const menu = (
      <Menu mode="horizontal" defaultSelectedKeys={["mail"]}>
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Dashboard",
            });
          }}
          key="two"
          icon={<PieChartOutlined />}
        >
          <a href="/investor-dashboard" style={{ fontSize: 14 }}>
            Dashboard
          </a>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Edit Profile",
            });
          }}
          key="four"
          icon={<UserOutlined />}
        >
          <a
            href="#"
            onClick={this.showprofiledetailspopup}
            style={{ fontSize: 14 }}
          >
            Edit Profile
          </a>
        </Menu.Item>
        {/* <Menu.Item key="five" icon={<BankOutlined />}>
          <a href="#" onClick={this.showbankingdetails} style={{ fontSize:14 }}>
            Bank Details
          </a>
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Logout",
            });
          }}
          key="six"
          icon={<ReloadOutlined />}
          style={{ width: 160 }}
        >
          <a
            href="#"
            style={{ fontSize: 14 }}
            onClick={() => this.setState({ modalVisible: true })}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    // Founder Menu
    const menu2 = (
      <Menu mode="horizontal" defaultSelectedKeys={["mail"]}>
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Dashboard",
            });
          }}
          key="two"
          icon={<PieChartOutlined />}
        >
          <a href="/founder-dashboard" style={{ fontSize: 14 }}>
            Dashboard
          </a>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Edit Profile",
            });
          }}
          key="four"
          icon={<UserOutlined />}
        >
          <a
            href="#"
            onClick={this.founder_profiledetailspopup}
            style={{ fontSize: 14 }}
          >
            Edit Profile
          </a>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            ReactGA.event({
              category: "Header",
              action: "Menu Item Button clicked. MenuItem:Logout",
            });
          }}
          key="five"
          icon={<ReloadOutlined />}
          style={{ width: 160 }}
        >
          <a
            href="#"
            style={{ fontSize: 14 }}
            onClick={() => this.setState({ modalVisible: true })}
          >
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <div className="new">
        <header
          className={` main-header site-header selector  ${isScrolled ? "shift" : ""
            }`}
          id="topNav"
        >
          <div class="">
            <div
              class="top-header newtopheader"
              style={{ background: "rgb(41, 23, 111);" }}
            ></div>
            <div
              className={`headers-main ${isActive ? "main-navbar-bg-white" : ""
                }`}
            >
              <nav class={`main-navbar ${newabout}`}>
                <div class="nav-inner ">
                  <div
                    className={`logo-menus ${isActive ? "directions-columns" : ""
                      }`}
                  >
                    <div
                      class="menu-logo"
                      style={
                        isActive
                          ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }
                          : { display: "bv" }
                      }
                    >
                      <Link to="/">
                        <img src="/web/white-logo.png" width="116" alt="img" />
                        <img
                          src="/web/growth91LOGO (4).png"
                          width="116"
                          alt="img"
                        />
                      </Link>
                      <div
                        class="menu-bar open-menu mobileuser"
                        style={
                          isActive
                            ? { display: "flex", width: "160px" }
                            : { display: "block" }
                        }
                      >
                        {this.state.loggedinstatus == true ? (
                          <ul
                            className={`topbar-right ${isActive ? "d-block" : ""
                              }`}
                          >
                            {/* <i className="bx bxs-bell-ring position-relative text-white me-4" style={{fontSize: '1.5rem', marginBottom: '0'}}>
                                
                                <span className="position-absolute top-0 start-100 translate-middle px-2 mt-2 ms-2 bg-danger border border-light rounded-circle">
                                  <span className="" style={{fontSize: '0.65rem', padding: "0px"}}>4+</span>
                                </span>
                              </i> */}
                            <li
                              style={{
                                borderLeft: 0,
                                paddingLeft: 8,
                              }}
                            >
                              {this.state.loggedinuser == "investor" ? (
                                <Dropdown overlay={menu}>
                                  <a onClick={(e) => e.preventDefault()}>
                                    {this.state.profileimagetoshow ? (
                                      <img
                                        src={this.state.profileimagetoshow}
                                        className="user-img"
                                        alt="profile"
                                        style={{
                                          width: 48,
                                          height: 48,
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                          border: "2px solid #fff",
                                        }}
                                      />
                                    ) : (
                                      <div className="user-wrappr">
                                        {this.state.firstname.slice(0, 1)}
                                        {this.state.lastname.slice(0, 1)}
                                      </div>
                                    )}
                                  </a>
                                </Dropdown>
                              ) : (
                                <Dropdown overlay={menu2}>
                                  <a onClick={(e) => e.preventDefault()}>
                                    {this.state.founder_profileimagetoshow ? (
                                      <img
                                        src={
                                          this.state.founder_profileimagetoshow
                                        }
                                        className="user-img"
                                        alt="profile"
                                        style={{
                                          width: 48,
                                          height: 48,
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                          border: "2px solid #fff",
                                        }}
                                      />
                                    ) : (
                                      <div className="user-wrappr">
                                        {this.state.name.slice(0, 2)}
                                      </div>
                                    )}
                                  </a>
                                </Dropdown>
                              )}
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}

                        {/* <!-- <i class="fa-solid fa-bars"></i> --> */}
                        <span
                          onClick={this.toggleMenu}
                          className={`expandMenu ${isActive ? "active" : ""}`}
                        >
                          <i></i>
                          <i></i>
                          <i></i>
                        </span>
                      </div>
                    </div>
                    <div
                      className="mobilenew-menus"
                      style={{ display: isActive ? "block" : "block" }}
                    >
                      <ul
                        className={`desktop-menu col-xl col-xxl col-sm col-md col-lg ${isActive ? "menumobile-opens" : ""
                          }`}
                        style={{
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <li>
                          <Link
                            to="/"
                            className={
                              window.location.pathname == "/" && "active"
                            }
                          >
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/deals"
                            className={
                              window.location.pathname == "/deals" && "active"
                            }
                          >
                            Deals
                          </Link>
                        </li>
                        <li>
                          <a
                            href="/Founders"
                            className={
                              window.location.pathname == "/Founders" &&
                              "active" ||
                              window.location.pathname == "/Founders/" &&
                              "active"
                            }
                          >
                            Founders
                          </a>
                        </li>

                        <li>
                          <Link
                            to="/resources"
                            className={
                              window.location.pathname == "/resources" &&
                              "active" ||
                              window.location.pathname == "/How-it-works/" &&
                              "active"
                            }
                          >
                            Resources
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/about"
                            className={
                              window.location.pathname == "/about" && "active"
                            }
                          >
                            About us
                          </Link>
                        </li>

                        <li>
                          <a
                            rel="noreferrer" href="https://growth91.com/blog/"
                            className={
                              window.location.pathname == "/Blog" && "active"
                            }
                          >
                            Blog
                          </a>
                        </li>
                        <li>
                          <a
                            rel="noreferrer"
                            className={
                              window.location.pathname == "" && "active"
                            }
                              href="/FutureUnicorn"
                          >
                            Future Unicorns
                          </a>
                          {/* <div className="submenu-cards">
                            <div className="submenus">
                              <ul>
                                <li><Link to="InformationList">Login as Founder</Link></li>
                                <li><Link to="FutureUnicornList">Login as Investor</Link></li>
                              </ul>
                            </div>
                          </div> */}

                        </li>
                        {this.state.loggedinstatus == true ? (
                          ""
                        ) : (
                          <>
                            {isActive ? (
                              <>
                                <div class="headers-btn  mobile-logins-btns">
                                  <Link to="/login">Sign In</Link>
                                  <Link to="/Signup">
                                    get started
                                  </Link>
                                </div>
                                {/* <li>
                                  <Link
                                    to="/Login"
                                    className={
                                      window.location.pathname == "/login" &&
                                      "active"
                                    }
                                  >
                                    Sign In
                                  </Link>
                                </li> */}
                                {/* <li>
                                  <Link
                                    to="/Signup"
                                    className={
                                      window.location.pathname == "/Signup" &&
                                      "active"
                                    }
                                  >
                                    Get Started
                                  </Link>
                                </li> */}
                              </>
                            ) : (
                              ""
                            )}{" "}
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                  {this.state.loggedinstatus == true ? (
                    <ul
                      className={`topbar-right mobile-topbar ${isActive ? "d-none" : ""
                        }`}
                    >
                      {/* <i className="bx bxs-bell-ring position-relative text-white me-4" style={{fontSize: '1.5rem', marginBottom: '0'}}>
                                
                                <span className="position-absolute top-0 start-100 translate-middle px-2 mt-2 ms-2 bg-danger border border-light rounded-circle">
                                  <span className="" style={{fontSize: '0.65rem', padding: "0px"}}>4+</span>
                                </span>
                              </i> */}
                      <li
                        style={{
                          borderLeft: 0,
                          paddingLeft: 8,
                        }}
                      >
                        {this.state.loggedinuser == "investor" ? (
                          <Dropdown overlay={menu}>
                            <a onClick={(e) => e.preventDefault()}>
                              {this.state.profileimagetoshow ? (
                                <img
                                  src={this.state.profileimagetoshow}
                                  className="user-img"
                                  alt="profile"
                                  style={{
                                    width: 48,
                                    height: 48,
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    border: "2px solid #fff",
                                  }}
                                />
                              ) : (
                                <div className="user-wrappr">
                                  {this.state.firstname.slice(0, 1)}
                                  {this.state.lastname.slice(0, 1)}
                                </div>
                              )}
                            </a>
                          </Dropdown>
                        ) : (
                          <Dropdown overlay={menu2}>
                            <a onClick={(e) => e.preventDefault()}>
                              {this.state.founder_profileimagetoshow ? (
                                <img
                                  src={this.state.founder_profileimagetoshow}
                                  className="user-img"
                                  alt="profile"
                                  style={{
                                    width: 48,
                                    height: 48,
                                    objectFit: "cover",
                                    borderRadius: "50%",
                                    border: "2px solid #fff",
                                  }}
                                />
                              ) : (
                                <div className="user-wrappr">
                                  {this.state.name.slice(0, 2)}
                                </div>
                              )}
                            </a>
                          </Dropdown>
                        )}
                      </li>
                    </ul>
                  ) : (
                    <div class="headers-btn">
                      <Link to="/login">Sign In</Link>
                      <Link to="/Signup">Get Started</Link>
                    </div>
                  )}
                </div>
              </nav>
            </div>
          </div>
        </header>

        <Modal
          title="Confirm"
          visible={this.state.modalVisible}
          onOk={this.logout}
          onCancel={this.handleCancel}
          okText="Yes"
          cancelText="No"
          style={{ maxWidth: 380 }}
        >
          <h6>Are you sure you want to sign out?</h6>
        </Modal>

        <Modal
          title="Update Bank Details"
          visible={this.state.bankdetailsmodal}
          onOk={this.updatebankdetails}
          onCancel={this.handleCancelbankdetails}
          okText="Update"
          cancelText="Cancel"
          style={{ maxWidth: 480 }}
        >
          <Spin spinning={this.state.formloader}>
            <div className="form-group">
              <label>Account Number</label>
              <input
                type="text"
                className="form-control"
                placeholder="Account Number"
                value={this.state.accountno}
                onChange={(e) => this.setState({ accountno: e.target.value })}
              />
            </div>
            <br />

            <div className="form-group">
              <label>IFSC Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="IFSC Code"
                value={this.state.ifsccode}
                onChange={(e) => this.setState({ ifsccode: e.target.value })}
              />
            </div>
          </Spin>
        </Modal>

        {/* profile update for investor */}
        <Modal
          title="Update Profile Details"
          visible={this.state.profiledetailsmodal}
          onOk={this.updateprofiledetails}
          onCancel={this.hideprofiledetailspopup}
          okText="Update"
          cancelText="Cancel"
          // style={{ maxWidth: 480 }}
          style={{ width: "100%", resize: "none" }}
        >
          <Spin spinning={this.state.formloader}>
            <div className="form-group mb-3">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.firstname}
                readOnly
                onChange={(e) => this.setState({ firstname: e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.middlename}
                readOnly
                onChange={(e) => this.setState({ middlename: e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.lastname}
                readOnly
                onChange={(e) => this.setState({ lastname: e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Contact No</label>
              <input
                type="text"
                className="form-control"
                value={this.state.contactno}
                readOnly
                onChange={(e) => this.setState({ contactno: e.target.value })}
              />
            </div>
            <div className="form-group mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={localStorage.getItem("investor_email")}
                readOnly
              // onChange={(e) => this.setState({ contactno:e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Profile Image</label>
              <br />
              {this.state.profileimagetoshow && (
                <img
                  style={{
                    width: 130,
                    borderRadius: "50%",
                    height: 130,
                    objectFit: "cover",
                    margin: "14px 0",
                  }}
                  src={this.state.profileimagetoshow}
                  alt="profile"
                  className="profile-image"
                />
              )}
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) => this.setState({ profile: e.target.files[0] })}
              />
            </div>
            <div className="form-group mb-3">
              <label>Membership Type:</label>
              <br />
              <div className="d-flex align-items-start">
                {this.state.membership_type == "premium" && (
                  <img
                    src="./assets/images/badge.png"
                    style={{ maxWidth: 40 }}
                  />
                )}
                <span style={{ textTransform: "capitalize" }}>
                  {this.state.membership_type}
                </span>
              </div>
            </div>
            {this.state.membership_type == "premium" ? (
              <>
                {" "}
                <div className="form-group mb-3">
                  <label>Membership Start Date:</label>
                  <br />
                  <div className="d-flex align-items-start">
                    <span
                      style={{ textTransform: "capitalize", paddingLeft: 9 }}
                    >
                      {this.state.membership_start_date
                        ? this.state.membership_start_date
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Membership Expiry Date:</label>
                  <br />
                  <div className="d-flex align-items-start">
                    <span
                      style={{ textTransform: "capitalize", paddingLeft: 9 }}
                    >
                      {this.state.membership_end_date
                        ? this.state.membership_end_date
                        : ""}
                    </span>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* Regular membership modal start  */}
            {this.state.investor_id &&
              this.state.membership_type &&
              this.state.membership_start_date &&
              this.state.membership_end_date && (
                <Membership
                  investor_id={this.state.investor_id}
                  type={this.state.membership_type}
                  start_date={this.state.membership_start_date}
                  end_date={this.state.membership_end_date}
                  closeModal={() => this.hideprofiledetailspopup()}
                  premium_button_show_status={
                    this.state.premium_button_show_status
                  }
                />
              )}
            {/* Regular membership modal End  */}
          </Spin>
        </Modal>
        {/* profile update for founder */}
        <Modal
          title="Update Profile Details"
          visible={this.state.founder_profiledetailsmodal}
          onOk={this.founder_updateprofiledetails}
          onCancel={this.founder_hideprofiledetailspopup}
          okText="Update"
          cancelText="Cancel"
          // style={{ maxWidth: 480 }}
          style={{ width: "100%", resize: "none" }}
        >
          <Spin spinning={this.state.formloader}>
            <div className="form-group mb-3">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.founder_firstname}
                readOnly
                onChange={(e) =>
                  this.setState({ founder_firstname: e.target.value })
                }
              />
            </div>

            <div className="form-group mb-3">
              <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.founder_middlename}
                readOnly
                onChange={(e) =>
                  this.setState({ founder_middlename: e.target.value })
                }
              />
            </div>

            <div className="form-group mb-3">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                value={this.state.founder_lastname}
                readOnly
                onChange={(e) =>
                  this.setState({ founder_lastname: e.target.value })
                }
              />
            </div>

            <div className="form-group mb-3">
              <label>Contact No</label>
              <input
                type="text"
                className="form-control"
                value={this.state.founder_contactno}
                readOnly
                onChange={(e) =>
                  this.setState({ founder_contactno: e.target.value })
                }
              />
            </div>

            <div className="form-group mb-3">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                value={localStorage.getItem("founder_email")}
                readOnly
              // onChange={(e) => this.setState({ contactno:e.target.value })}
              />
            </div>

            <div className="form-group mb-3">
              <label>Profile Image</label>
              <br />
              {this.state.founder_profileimagetoshow && (
                <img
                  style={{
                    width: 130,
                    borderRadius: "50%",
                    height: 130,
                    objectFit: "cover",
                    margin: "14px 0",
                  }}
                  src={this.state.founder_profileimagetoshow}
                  alt="profile"
                  className="profile-image"
                />
              )}
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={(e) =>
                  this.setState({ founder_profile: e.target.files[0] })
                }
              />
            </div>
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default NewWebHeader;
