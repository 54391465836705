import React, { useEffect } from "react";
import $ from "jquery";

export const Foundercurosel = () => {


  return (
    <div>
         <section className="testimonials-section t_desktop  d-block">
          <div className="container">
            <div className="heading-title">
              <p>
                <span></span>{" "}
              </p>
              <h2>Founder Testimonials
</h2>
            </div>
      <div className="testimonial_wraper owl-carousel">
        <div className="item">
          <div className="quotes">
            <img src="./web/images/quote.svg" alt="img" />
          </div>
          <p>
            Normally, startup fund raise is quite a tedious process. Knowing
            Growth91<sup>®</sup> modus operandi
            gives great confidence that we can focus on our core activities
          </p>
          <div className="media">
            <div className="images">
              <img src="./assets/images/deals-details/TM (1).jpeg" alt="img" />
            </div>
            <div className="media-body">
              <a href="#">Rahul Jain</a>
              <small>
                Managing Director, <br />
                InnoServ Group
              </small>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="quotes">
            <img src="./web/images/quote.svg" alt="img" />
          </div>
          <p>
            It is good to know that end to end work related to fund raise is
            taken care by Growth91
           <sup>®</sup>. Looking forward to
            list our deal at Growth91
           <sup>®</sup>
          </p>
          <div className="media">
            <div className="images">
              <img src="./assets/images/deals-details/TM (2).jpeg" alt="img" />
            </div>
            <div className="media-body">
              <a href="#">Saumya Shah</a>
              <small>
                Founder & CEO, <br />
                Tarrakki
              </small>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="quotes">
            <img src="./web/images/quote.svg" alt="img" />
          </div>
          <p>
            After knowing the details of modus operandi of Growth91
           <sup>®</sup>, it gives great
            confidence as the deal terms are truly balanced for investors and
            startups. Will plan to raise our next round at Growth91
           <sup>®</sup>
          </p>
          <div className="media">
            <div className="images">
              <img src="./assets/images/deals-details/TM (3).jpeg" alt="img" />
            </div>
            <div className="media-body">
              <a href="#">Dhruv Javeri</a>
              <small>
                Co-Founder & CEO, <br />
                Klassroom
              </small>
            </div>
          </div>
        </div>
      </div>
      </div>
        </section>
        {/* <section
          className="testimonials-section t_mobile"
          style={{
            textAlign: "justify",
            background:
              "linear-gradient(269.83deg, rgba(232, 226, 255, 0.6) 40.65%, rgba(255, 255, 255, 0.4) 100%),url(../images/testimonial-bg.png) no-repeat top center",
            backgroundSize: "cover",
            padding: "50px 0",
          }}
        >
          <div className="container">
            <div className="heading-title">
              <p>
                <span></span>{" "}
              </p>
              <h2>Testimonials Of Founders</h2>
            </div>
            <div className="testimonial_wraper_mobile">
              <div
                className="item mb-3"
                style={{
                  minHeight: "400px",
                  maxHeight: "400px",
                  background: "#fff",
                  padding: "18px 30px",
                  boxShadow: "0 30px 50px rgba(205, 196, 219, 0.3)",
                  borderRadius: "10px",
                }}
              >
                <div className="quotes" style={{ width: "30px" }}>
                  <img
                    style={{ maxWidth: "100%" }}
                    src="./web/images/quote.svg"
                    alt="img"
                  />
                </div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#313131",
                    fontFamily: '"Nunito", sans-serif',
                    padding: "20px 0",
                    textAlign: "justify",
                  }}
                >
                  Normally, startup fund raise is quite a tedious process.
                  Knowing Growth91<sup>®</sup>{" "}
                  modus operandi gives great confidence that we can focus on our
                  core activities
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                  className="media"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70px",
                      width: "70px",
                      textAlign: "center",
                      background: "#fff",
                      border: "1px solid #D3CBE2",
                      borderRadius: "50%",
                      overflow: "hidden",
                      lineHeight: "65px",
                    }}
                    className="images"
                  >
                    <img
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="./assets/images/deals-details/TM (1).jpeg"
                      alt="img"
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }} className="media-body">
                    <a
                      style={{
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#111111",
                        fontFamily: '"Nunito", serif',
                      }}
                      href="#"
                    >
                      Rahul Jain
                    </a>
                    <small
                      style={{
                        display: "block",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: '"Nunito", sans-serif',
                        color: "#313131",
                      }}
                    >
                      Managing Director, <br />
                      InnoServ Group
                    </small>
                  </div>
                </div>
              </div>
              <div
                className="item mb-3"
                style={{
                  minHeight: "400px",
                  maxHeight: "400px",
                  background: "#fff",
                  padding: "18px 30px",
                  boxShadow: "0 30px 50px rgba(205, 196, 219, 0.3)",
                  borderRadius: "10px",
                }}
              >
                <div className="quotes" style={{ width: "30px" }}>
                  <img
                    style={{ maxWidth: "100%" }}
                    src="./web/images/quote.svg"
                    alt="img"
                  />
                </div>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#313131",
                    fontFamily: '"Nunito", sans-serif',
                    padding: "20px 0",
                    textAlign: "justify",
                  }}
                >
                  It is good to know that end to end work related to fund raise
                  is taken care by Growth91
                 <sup>®</sup>. Looking forward
                  to list our deal at Growth91
                 <sup>®</sup>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                  }}
                  className="media"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70px",
                      width: "70px",
                      textAlign: "center",
                      background: "#fff",
                      border: "1px solid #D3CBE2",
                      borderRadius: "50%",
                      overflow: "hidden",
                      lineHeight: "65px",
                    }}
                    className="images"
                  >
                    <img
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="./assets/images/deals-details/TM (2).jpeg"
                      alt="img"
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }} className="media-body">
                    <a
                      style={{
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#111111",
                        fontFamily: '"Nunito", serif',
                      }}
                      href="#"
                    >
                      Saumya Shah
                    </a>
                    <small
                      style={{
                        display: "block",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: '"Nunito", sans-serif',
                        color: "#313131",
                      }}
                    >
                      Founder & CEO, <br />
                      Tarrakki
                    </small>
                  </div>
                </div>
              </div>

              <div
                className="item mb-3"
                style={{
                  minHeight: "400px",
                  maxHeight: "400px",
                  background: "#fff",
                  padding: "18px 30px",
                  boxShadow: "0 30px 50px rgba(205, 196, 219, 0.3)",
                  borderRadius: "10px",
                }}
              >
                <div className="quotes" style={{ width: "30px" }}>
                  <img
                    style={{ maxWidth: "100%" }}
                    src="./web/images/quote.svg"
                    alt="img"
                  />
                </div>
                <p
                  className="paradh"
                  style={{
                    fontSize: "17px",
                    fontWeight: "400",
                    color: "#313131",
                    fontFamily: '"Nunito", sans-serif',
                    padding: "20px 0",
                    textAlign: "justify",
                    marginbottom: "25px",
                  }}
                >
                  After knowing the details of modus operandi of Growth91
                 <sup>®</sup>, it gives great
                  confidence as the deal terms are truly balanced for investors
                  and startups. Will plan to raise our next round at Growth91
                 <sup>®</sup>
                </p>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                  className="media"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70px",
                      width: "70px",
                      textAlign: "center",
                      background: "#fff",
                      border: "1px solid #D3CBE2",
                      borderRadius: "50%",
                      overflow: "hidden",
                      lineHeight: "65px",
                    }}
                    className="images"
                  >
                    <img
                      style={{
                        height: "60px",
                        width: "60px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                      src="./assets/images/deals-details/TM (3).jpeg"
                      alt="img"
                    />
                  </div>
                  <div style={{ marginLeft: "10px" }} className="media-body">
                    <a
                      style={{
                        display: "block",
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#111111",
                        fontFamily: '"Nunito", serif',
                      }}
                      href="#"
                    >
                      Dhruv Javeri
                    </a>
                    <small
                      style={{
                        display: "block",
                        fontSize: "14px",
                        fontWeight: "400",
                        fontFamily: '"Nunito", sans-serif',
                        color: "#313131",
                      }}
                    >
                      Co-Founder & CEO, <br />
                      Klassroom
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

    </div>
  );
};
